<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                {{ $store.state.sysenv.codePropagated.name }}
                <small>{{ $store.state.sysenv.codePropagated.parentName }}</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd"></button>
                <div class="j_search type01">
                  <input v-model="search" type="search" name="q" placeholder="Filter and search" />
                  <button type="submit">
                    <v-icon>mdi-magnify</v-icon>
                  </button>
                </div>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            hide-actions
            item-key="idx"
            :headers="headers"
            :items="items"
            :search="search"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus(props)">
                <td style="text-align: center">{{ props.item.no }}</td>
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.phaseName }}</td>
                <td>{{ props.item.groupName }}</td>
                <td>{{ props.item.itemName }}</td>
                <td>{{ props.item.subItemName }}</td>
                <td>{{ props.item.desc }}</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>

      <form-modal 
        v-model="formOpened"
        :item-key="itemKey"
        @updated="onUpdated" />
    </div>
  </div>
</template>

<script>
import { PageComponentService } from '@/services'

// JGS Module
import __C from '@/primitives/_constant_'
import FormModal from './modal/SysenvPageSvgControl.modal'

export default {
  name: 'sysenv-dashboard',
  components: {
    FormModal
  },
  data: () => ({
    pageComponentService: null,
    formOpened: false,
    search: '',
    selected: [],
    selectedItem: {},
    selectedPrevItem: [],
    headers: [
      { type: 'conf', text: 'No', align: 'center', value: 'no', sortable: false, width: 50, },
      { type: 'text', text: 'Title', align: 'left', value: 'name', sortable: false, width: 150, },
      { type: 'text', text: 'Phase', align: 'left', value: 'phaseName', sortable: false, width: 120, },
      { type: 'text', text: 'Menu Group', align: 'left', value: 'groupName', sortable: false, width: 150, },
      { type: 'text', text: 'Menu Item', align: 'left', value: 'itemName', sortable: false, width: 180, },
      { type: 'text', text: 'Sub-Item', align: 'left', value: 'subItemName', sortable: false, width: 120, },
      { type: 'text', text: 'Desciption', align: 'left', value: 'desc', sortable: false },
    ],
    items: [],
    itemKey: null
  }),
  watch: {
    codePropagated: {
      handler(val) {
        this.getSvgPropsList()
      },
      deep: true
    },
    formOpened(val) {
      if(!val) this.itemKey = null
    }
  },
  created() {
    this.pageComponentService = new PageComponentService()
    console.log('here')
  },
  computed: {
    codePropagated: {
      get() { return this.$store.state.sysenv.codePropagated },
      set(val) { this.$store.commit('sysenv/codePropagate', val) }
    },
  },
  methods: {
    getSvgPropsList() {
      this.pageComponentService.getSvgPropsList(
        this.codePropagated.parentCode,
        this.codePropagated.groupCode,
        this.codePropagated.code,
        'ALL',
        this.setSvgProps,
      )
    },
    setSvgProps(res) {
      if (!res) res = []
      this.items = res
    },
    setAdd() {
      this.itemKey = ''
      this.selectedItem = {}
      this.selectedItem.idx = ''
      this.formOpened = true
    },
    propStatus(props) {
      this.selectedItem = Object.assign({}, props.item)
      this.itemKey = this.selectedItem.idx

      if (this.selectedPrevItem.length > 0) {
        if (this.selectedPrevItem[0].idx == props.item.idx) {
          this.formOpened = true
          return
        } else {
          let _selected = props.item
          this.selected = [_selected]
        }
      } else {
        props.selected = !props.selected
      }
      this.selectedPrevItem = [...this.selected]
      this.formOpened = true
    },
    onUpdated() {
      this.getSvgPropsList()
    }
  },
}
</script>
